import { bigScreenList, stateList, delBigScreen } from "@lib";
export default {
  data() {
    return {
      tableData: [],
      stateList: []
    };
  },
  created() {
    this.getState()
    this.getList()
  },
  methods: {
    async getState(){
      try {
        let result = await stateList();
        this.stateList = result
      } catch (error) {
        console.error(error);
      }
    },
    async getList(){
      try {
        let result = await bigScreenList();
        this.tableData = result
      } catch (error) {
        console.error(error);
      }
    },
    stateFilter(row){
      return this.stateList.find(m=>parseInt(m.code)===row.state)?.name||'-'
    },
    async onAdd() {
      this.$router.push({name: 'applicationAdd'})
    },
    async onEdit(row) {
      this.$router.push({name: 'applicationAdd', params:{id:row.id}})
    },
    async onDel(row) {
      try {
        let {success} = await delBigScreen({id:row.id});
        if(success){
          this.$message.success('删除成功')
        }
        this.getList()
      } catch (error) {
        console.error(error);
      }
    },
    async onView() {
    },
    async onSet(row) {
      this.$router.push({name: 'applicationSetting', params:{id: row.id}})
    }
  },
};
